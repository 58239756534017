<template>
  <s-table
    ref="table"
    size="default"
    :rowKey="record => record.id"
    :columns="columns"
    :data="loadData"
    showPagination="auto"
  >
    <span slot="name" slot-scope="text">{{ text | textFilter }}</span>
    <span slot="depart" slot-scope="text">{{ text | textFilter }}</span>
    <span slot="amount" slot-scope="text">{{ text | priceFilter }}</span>
    <span slot="action" slot-scope="text, record">
      <a @click="edit(record)">修改</a>
    </span>
  </s-table>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import InvoiceForm from '../../invoice/components/InvoiceForm'
import { moxiDistributors } from '@/api/moxi'
import { distributorColumns } from '../js/index'
export default {
  props: {
    detailId: {
      type: String,
      default: ''
    }
  },
  components: {
    STable, 
    Ellipsis,
    InvoiceForm
  },
  created() {
    this.id = this.detailId || this.$route.query.id
  },
  data() {
    return {
      id: null,
      loadData: parameter => {
        return moxiDistributors(this.dingUserId, this.id).then(res => {
          if (res.success) {
            const result = this.dResDataFormat(res)
            return result
          }
        })
      }
    }
  },
  computed: {
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    columns() {
      return distributorColumns
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    edit(record) {
      this.$refs.InvoiceForm.show(record)
    }
  }
}
</script>
