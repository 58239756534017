<template>
  <a-modal
    :title="noticeTitle"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="save"
    @cancel="close"
  >
    <template slot="footer">
      <a-button key="back" @click="close">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="save">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="应发佣金金额（元）">
          <a-input-number
            :autoFocus="true"

            placeholder="请输入应发佣金金额"
            v-decorator="['target_amount', { rules: [{ required: true, message: '请输入应发佣金金额' }] }]"
            allow-clear
            :maxLength="limitLength"
            :disabled="targetValidator"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="结佣金额（元）">
          <a-input-number
            :min="0"
            placeholder="请输入结佣金额"
            v-decorator="['amount', { rules: [{ required: true, message: '请输入结佣金额' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="结佣日期">
          <a-date-picker
            show-time
            class="inputLong"
            v-decorator="['created_time', { rules: [{ required: true, message: '请选择结佣日期' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { moxiCheckout } from '@/api/moxi'
export default {
  data() {
    return {
      noticeTitle: '财务结佣',
      fields: ['target_amount', 'amount', 'created_time'],
      form: this.$form.createForm(this),
      visible: false,
      loading: false,
      model: null,
      targetAmount: null,
      id: null,
      field_id: null
    }
  },
  computed: {
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    targetValidator() {
      if (this.targetAmount) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.formValidator(this.fields)
  },
  methods: {
    show(model) {
      this.model = null
      this.form.resetFields()
      if (model.field_id) {
        this.field_id = model.field_id
        this.model = model
        this.targetAmount = model.target_amount
        this.model.target_amount = model.target_amount
      }
      this.loading = false
      this.visible = true
      this.id = model.process_instance_id
    },
    save() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          let params = {
            amount: values.amount,
            target_amount: values.target_amount,
            created_time: this.dateFormat(values.created_time._d || values.created_time)
          }
          if (this.field_id) Object.assign(params, { field_id: this.field_id })
          params = this.formDataFormat(params)
          moxiCheckout(this.dingUserId, this.id, params)
            .then(res => {
              this.close()
              this.$emit('reload')
              this.notiSuccess(this.noticeTitle, '保存成功')
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    },
    close() {
      this.visible = false
    }
  }
}
</script>
