export const finishColumns = [
    {
        title: '成交人',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '成交人组别',
        dataIndex: 'group',
        scopedSlots: { customRender: 'group' }
    },
    {
        title: '成交人组别业绩',
        dataIndex: 'performance',
        scopedSlots: { customRender: 'performance' }
    }
]

export const distributorColumns = [
    {
        title: '分单人',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '分单人组别',
        dataIndex: 'depart',
        scopedSlots: { customRender: 'depart' }
    },
    {
        title: '分单人组别业绩',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    }
]

export const invoiceColumns = [
    {
        title: '开票金额',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: '已开票金额',
        dataIndex: 'invoiced_amount',
        scopedSlots: { customRender: 'completedAmount' }
    },
    {
        title: '开票时间',
        dataIndex: 'created_time',
        scopedSlots: { customRender: 'created_time' }
    }
]

export const financeInvoiceColumns = [
    ...invoiceColumns,
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export const paybackColumns = [
    {
        title: '回款金额',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: '已回款金额',
        dataIndex: 'paid_back_amount',
        scopedSlots: { customRender: 'completedAmount' }
    },
    {
        title: '开票时间',
        dataIndex: 'created_time',
        scopedSlots: { customRender: 'created_time' }
    }
]

export const financePaybackColumns = [
    ...paybackColumns,
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export const distributeColumns = [
    {
        title: '拆佣金额',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: '已拆佣金额',
        dataIndex: 'distributed_amount',
        scopedSlots: { customRender: 'completedAmount' }
    },
    {
        title: '开票时间',
        dataIndex: 'created_time',
        scopedSlots: { customRender: 'created_time' }
    }
]

export const financeDistributeColumns = [
    ...distributeColumns,
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export const checkoutColumns = [
    {
        title: '结佣金额',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: '已结佣金额',
        dataIndex: 'checked_amount',
        scopedSlots: { customRender: 'completedAmount' }
    },
    {
        title: '开票时间',
        dataIndex: 'created_time',
        scopedSlots: { customRender: 'created_time' }
    }
]

export const financeCheckoutColumns = [
    ...checkoutColumns,
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export const statusMap = {
    'RUNNING': {
        status: 'processing',
        color: '#2db7f5',
        text: '未完成'
    },
    'COMPLETED': {
        status: 'success',
        color: '#108ee9',
        text: '已完成'
    },
    'TERMINATED': {
        status: 'error',
        color: '#f50',
        text: '已终止'
    },
    undefined: {
        status: 'error',
        color: '#f50',
        text: '异常'
    }
}

export const flowTypeMap = {
    'START_PROCESS_INSTANCE': {
        text: '发起审批'
    },
    'EXECUTE_TASK_NORMAL': {
        text: '审批'
    },
    'EXECUTE_TASK_AGENT': {
        text: '代理人执行任务'
    },
    'APPEND_TASK_BEFORE': {
        text: '前加签任务'
    },
    'APPEND_TASK_AFTER': {
        text: '后加签任务'
    },
    'REDIRECT_TASK': {
        text: '转交任务'
    },
    'TERMINATE_PROCESS_INSTANCE': {
        text: '终止(撤销)'
    },
    'FINISH_PROCESS_INSTANCE': {
        text: '结束'
    },
    'ADD_REMARK': {
        text: '添加评论'
    },
    'REDIRECT_PROCESS': {
        text: '审批退回'
    },
    'PROCESS_CC': {
        text: '抄送'
    }
}

export const resultMap = {
    'AGREE': {
        text: '同意',
        color: 'green'
    },
    'NONE': {
        text: '办理',
        color: 'green'
    },
    'REFUSE': {
        text: '拒绝',
        color: 'red'
    }
}
