<template>
  <a-modal
    :title="noticeTitle"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="save"
    @cancel="close"
  >
    <template slot="footer">
      <a-button key="back" @click="close">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="save">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <p>请确认已完成！</p>
    </a-spin>
  </a-modal>
</template>

<script>
import { moxiCheckoutStatus } from '@/api/moxi'
export default {
  props: {
    noticeTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      id: null,
      fields: ['date', 'money']
    }
  },
  computed: {
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    }
  },
  methods: {
    show(record) {
      this.loading = false
      this.visible = true
      this.id = record.process_instance_id
    },
    save() {
      this.loading = true
      const data = new FormData()
      data.append('f_type', this.noticeTitle)
      moxiCheckoutStatus(this.dingUserId, this.id, data)
        .then(res => {
          if (res.success) {
            this.close()
            this.$emit('reload')
            this.notiSuccess(this.noticeTitle, '成功')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.visible = false
    },
    dateChange(date, dateString) {
      console.log(date, dateString)
    }
  }
}
</script>
