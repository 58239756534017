<template>
  <div>
    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
    >
      <span slot="completedAmount" slot-scope="text">{{ text | priceFilter }}</span>
      <span slot="amount" slot-scope="text">{{ text | priceFilter }}</span>
      <span slot="time" slot-scope="text">{{ text | textFilter }}</span>
      <span slot="action" slot-scope="text, record">
        <a @click="edit(record)">修改</a>
      </span>
    </s-table>
    <finance-form ref="FinanceForm" @reload="reload" />
  </div>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import FinanceForm from '../../finance/components/FinanceForm'
import { moxiPaybacks } from '@/api/moxi'
import { paybackColumns, financePaybackColumns } from '../js/index'
export default {
  props: {
    detailId: {
      type: String,
      default: ''
    }
  },
  components: {
    STable,
    Ellipsis,
    FinanceForm
  },
  created() {
    this.id = this.detailId || this.$route.query.id
  },
  data() {
    return {
      id: null,
      loadData: parameter => {
        return moxiPaybacks(this.dingUserId, this.id).then(res => {
          if (res.success) {
            const result = this.dResDataFormat(res)
            
            return result
          }
        })
      }
    }
  },
  computed: {
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    columns() {
      if (this.btnPermission('member_addition')) {
        return financePaybackColumns
      } else {
        return paybackColumns
      }
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    edit(record) {
      this.$refs.FinanceForm.show(record)
    }
  }
}
</script>
